

/* Invoice Page */
.invoice__container {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0rem;
      padding-bottom: 2.5rem;
      max-width: 1536px;
}

.invoice__title-container {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 1.25rem;
      border-radius: 0.375rem;
}

.invoice__content {
      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.invoice__content-container {
      padding: 2rem;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
}

.invoice__content-body {
      display: flex;
      padding-bottom: 1rem;
      flex-direction: column;
      justify-content: space-between;
      border-bottom-width: 1px;
      border-color: #f2f2f2;
}

.invoice__content-body_title {
      font-size: 1.5rem;
      line-height: 2rem;
      text-transform: uppercase;
      font-weight: 700;
}

.invoice__content-body_details {
      text-align: right;
}

.invoice__title-body_summary {
      color: #6b7280;
      font-size: 0.875rem;
      line-height: 1.25rem;
}

.invoice__content-body_image {
      margin-top: 1rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
}

.invoice__details {
      display: flex;
      padding-top: 1rem;
      flex-direction: column;
      justify-content: space-between;
}

.invoice__details-summary {
      display: block;
      color: #6b7280;
      font-size: 0.875rem;
      line-height: 1.25rem;
}

.invoice__details-title {
      display: block;
      color: #4b5563;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700;
      text-transform: uppercase;
}

.invoice__details-content {
      display: flex;
      margin-bottom: 0.75rem;
      flex-direction: column;
}

.invoice__details-description {
      display: flex;
      text-align: left;
      flex-direction: column;
}

@media (min-width: 640px) {
      .invoice__container {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
      }

      .invoice__content-body_image {
            margin-top: 0;
      }
}

@media (min-width: 768px) {
      .invoice__content-body {
            flex-direction: row;
      }

      .invoice__details {
            flex-direction: row;
      }

      .invoice__details-content {
            margin-bottom: 0;
      }
}

@media (min-width: 1024px) {
      .invoice__content-body {
            flex-direction: row;
            align-items: center;
      }

      .invoice__content-body_details {
            text-align: right;
      }

      .invoice__content-body_image {
            margin-top: 0;
      }

      .invoice__details {
            flex-direction: row;
      }

      .invoice__details-title {
            margin-bottom: 0;
      }

      .invoice__details-description {
            text-align: right;
      }
}

/* // Invoice Table */
.invoice__table {
      overflow: hidden;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
}

.invoice__table-container {
      overflow-x: auto;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
}

.invoice__table-content {
      min-width: 100%;
      border-width: 1px;
      border-color: #f3f4f6;
      border-top-width: 1px;
      border-color: #e5e7eb;
      table-layout: auto;
}

.invoice__table-header {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #374151;
      font-weight: 600;
      letter-spacing: 0.05em;
      text-transform: uppercase;
}

.invoice__table-data {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-weight: 400;
      white-space: nowrap;
}

.invoice__table-name {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #6b7280;
      font-weight: 400;
      white-space: nowrap;
      display: flex;
      align-items: center;
      grid-gap: 10px;
}

.invoice__table-quantity {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
}

.invoice_table-total {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #ef4444;
      font-weight: 700;
      text-align: right;
      white-space: nowrap;
}

.invoice_table-price {
      color: red;
}

@media (min-width: 1024px) {
      .invoice__table {
            overflow: visible;
      }
}

.invoice__payment {
      padding: 2.5rem;
      padding-top: 1rem;
      border-top-width: 1px;
      border-bottom-width: 1px;
      border-color: #f3f4f6;
}

.invoice__payment-content {
      display: flex;
      padding-top: 1rem;
      flex-direction: column;
      justify-content: space-between;
}

@media (min-width: 768px) {
      .invoice__payment-content {
            flex-direction: row;
      }
}

@media (min-width: 1024px) {
      .invoice__payment-content {
            flex-direction: row;
      }
}
