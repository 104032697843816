@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* for fonts  */
/* :root {
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Roboto Condensed", sans-serif;
} */

/* for colors  */
:root {
      --primary-color: #008080;
      --secondary-color: #19504c;
      --black-color: #091e1c;
      --pitch-black: #010101;
      --white-color: #ffffff;
      --text-color: #e6e6e6;
      --divider-color: #18181833;
      --paragraph-color: #646464;
      --border-color: #e4e4e4;
      --footer-border-color: #d2d2d2;
      --footer-paragraph-color: #7d7d7d;
      --background-color: #1c1c1c;
      --bs-btn-close-bg: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>";
}

* {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: 'Roboto', sans-serif;
      font-family: 'Roboto Condensed', sans-serif;
}

a {
      text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
      font-family: inherit;
      font-weight: lighter !important;
      color: inherit;
      margin: 0;
}

a:hover {
      text-decoration: none !important;
}

/* header section css */
/* header css */
/* navigation link css */
.header__nav nav ul li > a {
      text-decoration: none;
      color: #333;
      font-size: 16px;
      padding: 20px;
      letter-spacing: 0.04rem;
      position: relative;
}

.header__nav nav ul li i {
      margin-left: -16px;
}

.header__nav nav ul li > a::after {
      transform: translateX(-50%);
      position: absolute;
      bottom: 14px;
      left: 50%;
      content: '';
      background: var(--white-color);
      width: 0%;
      height: 1px;
}

.header__nav nav ul li:hover > a::after {
      width: 65%;
      transition: width 0.3s ease;
}

.header__nav svg {
      fill: var(--white-color);
      height: 24px;
}

/* header dropdown css */
.dropdown__container {
      position: relative;
}

.dropdown__wrapper {
      display: none;
      position: absolute;
      top: 40px;
      background-color: var(--white-color);
      width: 200px;
      z-index: 9999;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dropdown__container:hover .dropdown__wrapper {
      display: block;
}

.dropdown__container .dropdown__wrapper .dropdown__content {
      padding: 10px;
}

.dropdown__container .dropdown__wrapper .dropdown__content a {
      color: var(--paragraph-color);
      transition: all 0.5s ease-out 0s;
}

.dropdown__container .dropdown__wrapper .dropdown__content a:hover {
      color: var(--primary-color);
      margin-left: 20px;
}

/* menu css */
.menu__wrapper {
      padding: 0px 20px;
}

.menu__wrapper:hover {
      cursor: pointer;
}

.menu__wrapper .menu__container .custom-2 {
      display: inline-block;
      width: 72px;
      height: 14px;
      position: relative;
      text-decoration: none;
}

.menu__wrapper .menu__container .custom-2 span {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #363636;
      -webkit-transition: all 0.5s ease;
      -khtml-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
}

.menu__wrapper .menu__container .custom-2 .hover.top {
      width: 0px;
      top: 3px;
      bottom: auto;
      -webkit-transform: translateX(-150%);
      -khtml-transform: translateX(-150%);
      -moz-transform: translateX(-150%);
      -ms-transform: translateX(-150%);
      -o-transform: translateX(-150%);
      transform: translateX(-150%);
      opacity: 0;
      visibility: hidden;
}

.menu__wrapper .menu__container .custom-2 .hover.bottom {
      width: 0;
      top: 10px;
      bottom: auto;
      -webkit-transform: translateX(150%);
      -khtml-transform: translateX(150%);
      -moz-transform: translateX(150%);
      -ms-transform: translateX(150%);
      -o-transform: translateX(150%);
      transform: translateX(150%);
      opacity: 0;
      visibility: hidden;
}

.menu__wrapper .menu__container .custom-2 .default.top {
      width: 100%;
      top: 3px;
      bottom: auto;
}

.menu__wrapper .menu__container .custom-2 .default.bottom {
      width: 100%;
      top: 10px;
      height: 0.5px;
      bottom: auto;
}

.menu__wrapper .menu__container .custom-2:hover .hover.top {
      width: 100%;
      -webkit-transform: translateX(0);
      -khtml-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
}

.menu__wrapper .menu__container .custom-2:hover .hover.bottom {
      width: 100%;
      -webkit-transform: translateX(0);
      -khtml-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
}

.menu__wrapper .menu__container .custom-2:hover .default.top {
      width: 0;
      -webkit-transform: translateX(150%);
      -khtml-transform: translateX(150%);
      -moz-transform: translateX(150%);
      -ms-transform: translateX(150%);
      -o-transform: translateX(150%);
      transform: translateX(150%);
      opacity: 0;
      visibility: hidden;
}

.menu__wrapper .menu__container .custom-2:hover .default.bottom {
      width: 0;
      -webkit-transform: translateX(-150%);
      -khtml-transform: translateX(-150%);
      -moz-transform: translateX(-150%);
      -ms-transform: translateX(-150%);
      -o-transform: translateX(-150%);
      transform: translateX(-150%);
      opacity: 0;
      visibility: hidden;
}

/* swiper css */
.swiper {
      width: 100%;
      height: 100%;
}

.header .swiper {
      height: 100%;
}

.swiper-slide {
      background-position: center;
      background-size: cover;
      position: relative;
}

.swiper-slide img {
      display: block;
      width: 100%;
}

.swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
}

.header .swiper-slide img {
      -webkit-animation: slider-animation 30s ease-out infinite;
      animation: slider-animation 30s ease-out infinite;
}

/* custom slider pagination */
.header .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
      width: 1% !important;
      transform: translate(35px, -70px);
}

.header .swiper-pagination {
      position: relative;
}

.header .swiper-pagination-bullet {
      width: 10px !important;
      height: 10px !important;
      display: inline-block;
      border-radius: 0 !important;
      background: var(--primary-color) !important;
      opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}

.header .swiper-pagination-bullet-active {
      opacity: var(--swiper-pagination-bullet-opacity, 1);
      background: var(--white-color) !important;
}

.header .swiper-pagination::before {
      content: '';
      position: absolute;
      background-color: var(--primary-color);
      width: 0.5px;
      height: 240px;
      left: 50%;
      top: -246px;
}

.header .swiper-pagination::after {
      content: '';
      position: absolute;
      background-color: var(--primary-color);
      width: 0.5px;
      height: 80px;
      left: 50%;
}

/* slider image scale animation  */
@keyframes slider-animation {
      0% {
            transform: scale(1.1);
      }

      100% {
            transform: scale(1.3);
      }
}

/* contact us button on slider */
.contact__button {
      position: absolute;
      top: 315px;
      left: -60px;
      z-index: 10000;
}

.contact__button button {
      background-color: var(--white-color);
      padding: 5px 15px;
      border-radius: 0;
      text-transform: uppercase;
      font-size: 14px;
}

.contact__button button:hover {
      border: 1px solid #fff;
      color: var(--primary-color);
      background-color: #f5f5f5;
}

.contact__button button:active {
      border: 1px solid #fff;
      color: var(--primary-color) !important;
      background-color: #f5f5f5 !important;
}

.rotated__text {
      transform: rotate(270deg);
}

.contact__button button i {
      color: var(--primary-color);
      font-size: 35px;
}

/* about us */
.left__pad {
      padding-left: calc((100% - 1350px) / 2) !important;
}

.top__margin {
      margin: 130px 0px 0px 44px;
}

.about__right__image {
      position: relative;
}

.about__right__image img {
      height: 620px;
}

.about__right__image::after {
      content: ' ';
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
}

.rotated__heading {
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 30px;
      letter-spacing: 1.6px;
      width: 96px;
      transform: rotate(270deg);
}

.about__small__container {
      border-left: 1px solid var(--divider-color);
      margin-left: -25px;
}

.container__heading {
      font-size: 48px;
      font-weight: 400;
      border-bottom: 1px solid var(--divider-color);
      padding: 0px 10px 30px 53px;
}

.container__heading > span {
      color: #363636;
}

.description {
      padding: 20px 10px 30px 53px;
}

.about__description p {
      color: #646464;
}

.description p {
      font-size: 18px;
      line-height: 30px;
      font-family: 'Roboto', sans-serif;
}

.right__width {
      width: 30px;
      margin-top: 6px;
}

/* progress bar */
.progress__bar__content {
      padding-right: 80px;
}

.progress__bar__content .progress__item {
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
}

.progress__bar__content .progress__item + .progress__item {
      margin-top: 20px;
}

.progress__bar__content .progress__item .progress__title {
      flex: none;
      font-size: 15px;
      line-height: 30px;
      color: #000;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      min-width: 102px;
      margin-right: 10px;
}

.progress__bar__content .progress__item .progress__bar__wrap {
      flex: 1 0;
}

.progress__bar__content .progress__item .progress__percentage {
      flex: none;
      padding-left: 22px;
      font-size: 15px;
      line-height: 30px;
      color: #000;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
}

.progress__bar__content .progress__bar__wrap {
      position: relative;
}

.progress__bar__content .progress__bar__wrap .progress__bound {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #e5e5e5;
      -webkit-border-radius: 0;
      -khtml-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
}

.progress__bar__content .pxl__progress__bar {
      height: 3px;
      position: relative;
      width: 0;
      background-color: var(--primary-color);
      -webkit-transition: width 1.4s ease;
      -khtml-transition: width 1.4s ease;
      -moz-transition: width 1.4s ease;
      -ms-transition: width 1.4s ease;
      -o-transition: width 1.4s ease;
      transition: width 1.4s ease;
      -webkit-border-radius: 0;
      -khtml-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
}

.progress__bar__content .pxl__progress__bar:after {
      content: '';
      width: 3px;
      height: 19px;
      position: absolute;
      top: -8px;
      right: 0;
      background-color: var(--primary-color);
}

.about__button a {
      background-color: var(--primary-color);
      display: block;
      color: var(--white-color);
}

/* gsap scrolling css */
.demo__gallery img {
      width: 100%;
      height: auto;
      background: #f0f0f0;
}

.demo__gallery ul {
      padding-left: 1rem;
      list-style: none;
}

.demo__gallery li {
      flex-shrink: 0;
      width: clamp(1200px, 60vw, 800px);
      padding-right: 1rem;
}

.demo__wrapper {
      overflow: hidden;
      margin-bottom: -16px;
}

.wrapper {
      display: flex;
}

.demo__gallery:not(.last) {
      padding-bottom: 1rem;
}

.demo__text .text {
      font-size: 20.5vw;
      color: #5d5d5d;
      width: 112vw;
      line-height: 0.7;
      background: -webkit-linear-gradient(
            85deg,
            var(--primary-color),
            #f9f9f9 53%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
}

/* service section css */
.service__slider__container {
      background-image: linear-gradient(
                  rgba(4, 9, 30, 0.5),
                  rgba(4, 9, 30, 0.4)
            ),
            url(/public/images/img/service.jpg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 175px;
}

.service__inner {
      background-color: #1d1d1d;
      color: var(--white-color);
      padding: 40px 30px 40px;
      width: 100%;
      height: 333px !important;
      position: relative;
}

.service__overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: var(--primary-color);
      opacity: 0;
      transform: scale(0.5);
      transition: opacity 0.3s ease, transform 0.3s ease;
}

.service__inner:hover {
      cursor: pointer;
}

.service__inner:hover .service__overlay {
      opacity: 1;
      transform: scale(1.05);
}

.service__inner:hover .service__image svg {
      fill: var(--white-color);
      transform: scale(1.05);
      transition: fill 0.3s ease, transform 0.3s ease;
}

.service__inner:hover .service__inner__content p {
      display: none;
}

.service__inner:hover .service__inner__content a {
      display: block;
}

.service__content {
      position: relative;
}

.number__wrap {
      margin-left: 183px;
}

.service__inner svg {
      fill: var(--primary-color);
      width: 60px;
      height: 60px;
}

.service__inner h3 {
      font-size: 18px;
      color: #fff;
      letter-spacing: 0.1em;
      position: relative;
}

.service__inner__content {
      position: relative;
}

.service__inner p {
      font-size: 15px;
      line-height: 24px;
      color: #9d9d9d;
      position: absolute;
      top: 18px;
      display: block;
      line-height: 1.6;
}

.service__inner a {
      display: none;
}

.service__inner .service__inner__content a::after {
      top: 7px;
      left: 7px;
      border-color: var(--white-color);
}

.service__title {
      position: relative;
}

.service__title::after,
.service__title::before {
      position: absolute;
      content: '';
      top: 40px;
      left: 0;
      height: 2px;
}

.service__title::after {
      width: 100%;
      background-color: var(--footer-border-color);
}

.service__title::before {
      width: 0;
}

.service__inner:hover .service__title::before {
      background-color: var(--white-color);
      width: 100px;
      height: 2px;
      transition: width 0.3s ease-in;
      z-index: 100;
}

.service__inner:hover .service__title::after {
      background-color: var(--paragraph-color);
}

/* testimonial section css */

.testimonial__vector--image {
      position: absolute;
}

.testimonial__vector--position1 {
      right: 71px;
      top: 128px;
}

.testimonial__vector--position2 {
      bottom: 39px;
}

.testimonial__image {
      width: 245px;
      height: 296px;
}

.left__rounded__corner {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
}

.testimonial__wrapper {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      margin: 20px auto;
      border-radius: 12px;
}

.testimonial__content {
      padding: 45px 50px 45px 52px;
      background-color: #f5f5f5;
      border-radius: 12px;
}

.testimonial__content .testimonial__date span {
      font-size: 12px;
      line-height: 40px;
      color: #525252;
}

.testimonial__description p {
      color: #525252;
      text-align: justify;
}

.second__headingstyle {
      font-size: 48px;
}

.second__headingstyle span {
      color: var(--primary-color);
}

/* portfolio css */
.project__container {
      background-image: linear-gradient(
            to right,
            rgb(0, 128, 128),
            rgb(54, 54, 54)
      );
      margin-left: 112px;
      padding: 40px 0 40px 40px;
}

.project__small__container {
      border-left: 1px solid var(--white-color);
      margin-left: -25px;
}

.project .button-1::after,
.project .button-1::before {
      border-color: var(--white-color);
}

.project .container__heading {
      border-color: var(--white-color);
}

.project__description p {
      color: #bfbfbf;
      text-align: justify;
}

.project__slider {
      margin-right: -107px;
      overflow: hidden;
}

.overflow__hidden {
      overflow: hidden;
}

.project__slider--container {
      position: relative;
      overflow: hidden;
}

.project__slider--content {
      position: absolute;
      z-index: 100;
      top: 0;
      left: -275px;
      opacity: 1;
}

.project__slider--number {
      position: absolute;
      z-index: 100;
      top: 415px;
      padding: 30px;
      background-color: #1d1d1d;
}

.project__slider--content {
      background-color: rgba(0, 0, 0, 0.3);
}

.project__slider--button a {
      background: var(--white-color);
      padding: 15px 5px;
}

.project__slider--button svg {
      transform: rotate(315deg);
}

.project__slider--container:hover .project__slider--content {
      transform: translateX(275px);
      -webkit--transition: transform 0.3s ease, opaicty 0.3s ease;
      transition: transform 0.3s ease, opacity 0.3s ease;
      opacity: 1;
      cursor: pointer;
}

.project__slider--container:hover .project__slider--number {
      background-color: var(--primary-color);
}

.project__slider--button:hover > a {
      background: var(--primary-color);
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
}

.project__slider--button a:hover svg {
      transform: rotate(360deg);
      transition: transform 0.3s ease;
      fill: black;
}

/* /blog section css */
.blog img {
      filter: grayscale(100%);
}

/* footer css  */
.footer {
      /* color: white; */
}

.footer-container {
      background-color: #1d1d1d;
      padding: 70px 0 70px 0;
}

.footer-title h3 {
      font-size: 40px;
      padding-left: 40px;
}

.footer-content-wrapper h4,
.footer-content-wrapper p {
      /* font-size: 14px !important; */
}

.footer-content-wrapper p {
      color: var(--footer-paragraph-color);
}

.footer-container span {
      color: var(--primary-color);
}

.footer-content-gird {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--footer-border-color);
      padding-left: 40px;
}

.footer-credentials {
      background-color: #0f0f0f;
      font-size: 14px;
      padding: 20px 50px;
      padding-bottom: 20px;
}

.footer-credentials a {
      color: var(--primary-color);
}

.footer-credentials span {
      color: var(--primary-color);
}

.footer-subscribe-box {
      background-color: var(--text-color);
      width: 400px;
      height: auto;
}

.footer-subscribe-box input {
      outline: none;
      border: none;
      background: transparent;
}

.footer-subscribe-box button {
      background-color: var(--primary-color);
      width: calc(100% - 300px);
}

.footer-subscribe-box button:hover {
      background-color: var(--secondary-color);
}

/* breadcrumbs  css start*/
.breadcrumbs {
      padding: 140px 0 60px 0;
      min-height: 30vh;
      position: relative;
      background-size: cover;
      background-position: start;
      background-repeat: no-repeat;
}

.breadcrumbs:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      inset: 0;
}

.breadcrumbs h2 {
      font-size: 56px;
      font-weight: 500;
      color: #fff;
      font-family: var(--font-secondary);
}

.breadcrumbs ol {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0 0 10px 0;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-color);
}

.breadcrumbs ol a {
      color: rgba(255, 255, 255, 0.8);
      transition: 0.3s;
}

.breadcrumbs ol a:hover {
      text-decoration: underline;
}

.breadcrumbs ol li + li {
      padding-left: 10px;
}

.breadcrumbs ol li + li::before {
      display: inline-block;
      padding-right: 10px;
      color: #fff;
      content: '••';
}

/* breadcrumbs css ends */

/* shoplanding page css starts */
.rhombus {
      /* clip-path: polygon(50% 0, 100% 52%, 50% 100%, 0 50%); */
      border-radius: 50%;
      height: 137.14px;
      background-color: black;
      /* background: url(/public//images/img/tiles.jpg); */
      background-size: cover;
}

.rhombus img {
      border-radius: 50%;
}

.category {
      padding: 30px 0;
}

.category h3 {
      font-size: 20px;
      margin: 0 0 30px 0;
      font-weight: 300;
      font-style: italic;
}

.category h3 span {
      color: var(--primary-color);
      font-size: 43px;
      font-weight: 300;
}

.category h5 {
      font-weight: 300;
      font-style: italic;
      padding: 10px;
      color: #1c1c1c;
}

.shop__services--icon svg {
      height: 50px;
      width: auto;
      fill: var(--primary-color);
      stroke: var(--primary-color);
}

.shop__services--content p {
      color: #646464;
      font-size: 14px;
}

.shop__services h5 {
      color: var(--primary-color);
}

.shop__banner img::after,
.shop__banner img::before {
}

@media screen and (max-width: 990px) {
      .hide {
            display: none;
      }
}

.product {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      padding: 20px;
      background-color: #fff;
}

.product__image {
      /* height: 178px; */
      display: flex;
      align-items: center;
      justify-content: center;
}

.product__title h4 {
      line-height: 24px;
}

.product__title a h4 {
      font-size: 16px;
      color: #333;
}

.product__price {
      font-size: 16px;
      color: #525252;
      font-weight: 600;
}

.title {
      position: relative;
      font-weight: 300;
      font-style: italic;
      padding: 15px 20px;
}

.title::after {
      position: absolute;
      content: '';
      height: 3px;
      width: 20px;
      background-color: #008080;
      top: 30px;
      left: 0;
}

.brand__logo {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.brand__logo img {
      height: auto;
}

/* product list css start */
.category__list ul li {
      list-style-type: none;
}

.category__list ul li a {
      list-style-type: none;
      font-size: 16px;
      color: var(--background-color);
}

.category__list ul li > ul li a {
      color: #206460;
      font-size: 16px;
      font-weight: 300;
}

.product__list--title h2 {
      border-bottom: 1px dotted #000000;
      font-size: 20px;
}

.category__color span {
      border: 1px solid #ddd;
      padding: 10px 20px;
      background: #000000;
      border-radius: 50%;
}

.size ul li {
      list-style-type: none;
}

.size a {
      color: #206460;
}

.metismenu .has-arrow::after {
      transform: rotate(135deg) translate(0, -50%);
}

.metismenu .mm-active > .has-arrow::after,
.metismenu .has-arrow[aria-expanded='true']::after {
      transform: rotate(225deg) translate(0, -50%);
}

/* price range slider css */
.price__range form input {
      padding: 10px 20px;
      background-color: var(--primary-color);
      color: #fff;
}

.rs-slider-progress-bar {
      background-color: var(--primary-color) !important;
      height: 4px !important;
}

.rs-slider-handle::before {
      width: 3px !important;
      border-radius: 0 !important;
      border: 2px solid var(--primary-color) !important;
      background-color: var(--primary-color) !important;
      margin-left: -1px !important;
      transform: skewX(21deg);
}

.product__tags a {
      color: var(--primary-color);
      border: 1px solid #b7b7b7;
      padding: 10px 20px;
}

.product__tags a:hover {
      background-color: var(--primary-color);
      color: #fff;
      --webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;
}

/* product details page start */
.product__details .swiper-slide,
.product__details .swiper {
      height: auto !important;
      object-fit: contain;
}

/* product details page endss */
.product__content--title h1 {
      color: var(--primary-color);
      font-size: 28px;
      line-height: normal;
}

.product__content--price {
      font-size: 19px;
}

.product__content hr {
      color: var(--background-color);
      border-top: 1px solid #a2aba9;
}

.product__content--shortdesc p {
      font-size: 15px;
      color: #1b1b1b;
      font-weight: 300;
}

.product__quantity {
      display: inline-block;
}

.product__cart {
      display: inline-block;
}

.input__spinner {
      border: 1px solid #a2aba9;
}

.input__spinner i {
      font-size: 12px;
      color: #a2aba9;
}

.subtract {
      border-right: 1px solid #a2aba9;
}

.quantity {
      padding: 7px 20px;
      color: #525252;
      font-size: 15px;
}

.add {
      border-left: 1px solid #a2aba9;
}

.add,
.subtract {
      cursor: pointer;
      padding: 7px 10px;
}

.add:hover,
.subtract:hover {
      background: var(--primary-color);
}

.add:hover i,
.subtract:hover i {
      color: #fff;
}

.button-2 {
      padding-left: 10px;
      padding-right: 10px;
      color: #fff;
      position: relative;
}

.button-2::before {
      transition: all 0.5s ease;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      height: 8px;
      width: 8px;
}

.button-2 button {
      color: #fff;
      z-index: 1;
      position: relative;
}

.button-2:hover::before {
      transition: all 0.5s ease;
      width: 100%;
      height: 100%;
}

.product__cart {
      background-color: var(--primary-color);
}

.product__cart.button-2::before {
      background: #000000;
}

.product__buy {
      background-color: #000;
}

.product__buy.button-2::before {
      background: var(--primary-color);
}

.product__meta ul li {
      list-style-type: none;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 300;
      color: #1b1b1b;
}

.product__meta ul li span {
      color: #1c1c1c;
}

.product__additional ul li button {
      font-size: 18px;
      font-weight: 300;
}

.product__desc ul li::marker {
      color: var(--primary-color);
}

.product__desc {
      padding: 30px;
}

.product__desc {
      font-size: 16px;
      font-weight: 300;
}

.nav-link,
.nav-link:hover {
      color: #333;
}

.nav-tabs .nav-link.active {
      position: relative;
      transition: all 0.3s linear;
}

.nav-tabs .nav-link.active::after {
      position: absolute;
      content: ' ';
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      background: var(--primary-color);
      transition: all 0.3s linear;
}
/* product details page css */
.product__toprating--icons {
      font-size: 19px;
      color: var(--rating-color);
}
.product__toprating--info {
      color: var(--footer-paragraph-color);
}
.img-fit {
      display: block;
      height: 70px;
      width: 70px;
      object-fit: contain;
}
.product__suggest--content {
      padding: 10px;
      background-color: var(--bs-white);
      border-color: transparent;
      -webkit-box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
            0px 10px 20px -3px rgba(145, 158, 171, 0.05);
      box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
            0px 10px 20px -3px rgba(145, 158, 171, 0.05);
      border-radius: 0.25rem;
}
.product__suggest--info h5 {
      font-size: 16px;
      color: #555;
      font-weight: 400;
}
.product__sugest--review i {
      color: var(--rating-color);
}
.product__suggest--info p {
      color: var(--secondary-color);
      font-weight: 600;
}

.mm-navbars.mm-navbars--top {
      display: none !important;
}
